import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TestimonialsPage = ({ data, ...props }) => (
  <Layout pathname={props.location.pathname}>
    <SEO title="Our Client Testimonials" />
    <h1>Client Testimonials</h1>

    <p>
      <Img
        fluid={data.bathroomImage.childImageSharp.fluid}
        className="my-4 rounded-sm"
        alt="Bathroom"
      />
    </p>
    <p>
      <em>
        "Dave and his team renovated my 2 bathrooms and a powder room during COVID lockdown and I cannot recommend him highly enough. He is very personable, efficient, reliable and with great attention to detail. From our original discussions through to completion, there was not a hitch and the project was delivered on time and within budget.
        </em>
    </p>

    <p>
      <em>
        Given the situation, during the build we only communicated by phone or text with videos and photos and were both surprised how well a remote renovation worked! Most importantly, the bathrooms are beautifully finished in every way."
      </em>
    </p>

    <p>
      <strong>
        Annie Moulden
        <br />
        Prahran
      </strong>
    </p>

    <p>
      <Img
        fluid={data.dentistImage.childImageSharp.fluid}
        className="my-4 rounded-sm"
        alt="Dentist"
      />
    </p>
    <p>
      <em>
        "When planning our new dental surgery I went through the process of
        tendering our project to a number of builders. Many were "dental
        specialised" builders, however none really impressed me with attitude,
        reliability, and many seemed like I was just another project for their
        cookie cutter approach.
      </em>
    </p>

    <p>
      <em>
        I was recommended to Dave and Tim from a previous client, and straight
        away I was blown away. The amount of effort put into obtaining a
        realistic quote, flexibility in discussion of options, and general
        professionalism was outstanding. I was instantly impressed, and upon
        engaging them I was not let down at all.
      </em>
    </p>

    <p>
      <em>
        They were amazingly reliable, always approachable, kept me informed of
        every process, and were a dream to deal with. The project was delivered
        on time, on budget and far exceeded my expectations. By far the most
        exceptional building team I've ever dealt with, and they are my go to
        guys for any projects in the future."
      </em>
    </p>

    <p>
      <strong>
        Dr Dan Andrews
        <br />
        Hobsons Bay Dental Clinic, Williamstown
      </strong>
    </p>

    <Img
      fluid={data.georgeImage.childImageSharp.fluid}
      className="my-4 mt-8 rounded-sm"
      alt="George"
    />
    <p>
      <em>
        "We couldn't have been more impressed with the professionalism and
        attention to detail from both Dave and Tim. They coordinated our kitchen
        installation from start to finish and delivered an exceptional end
        product. They were a pleasure to deal with, extremely punctual and
        thorough with every aspect of the project."
      </em>
    </p>
    <p>
      <strong>
        George Stuckey
        <br />
        Kitchen Renovation, Greensborough
      </strong>
    </p>
  </Layout>
)

export default TestimonialsPage

export const query = graphql`
  query {
    bathroomImage: file(relativePath: { eq: "testimonials/bathroom.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dentistImage: file(relativePath: { eq: "testimonials/dentist.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    georgeImage: file(relativePath: { eq: "testimonials/george.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
